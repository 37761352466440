<template>
  <div class="admin-integration">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Integration
      </h1>
    </div>
    <!-- / Page Header -->

    <!-- Menu -->
    <div class="menu">
      <a-row type="flex" :gutter="20">
        <a-col :span="colSpan" v-for="(item, i) in menuItems" :key="i">
          <admin-menu-item
            :img="item.img"
            :title="item.title"
            @click.native="() => handleItemClicked(item)"
          ></admin-menu-item>
        </a-col>
      </a-row>
    </div>
    <!-- / Menu -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminMenuItem from "../../components/Admin/AdminMenuItem.vue";
export default {
  components: {AdminMenuItem},
  data() {
    return {
      windowWidth: window.innerWidth,

      menuItems: [
        {
          title: "Smart Buttons",
          description: "Configure smart buttons registered by your colleagues",
          onClick: () => {
            this.$router.push("/admin/integration/smart-buttons");
          },
          img: "/admin/smart-buttons.jpg",
        },
        {
          title: "Partners",
          description:
            "Save time by integrating teamSOS with our partners to join your incident processes together smoothly",
          onClick: () => {
            this.$router.push("/admin/integration/partners");
          },
          img: "/admin/partners.jpg",
        },
        {
          title: "API Clients",
          description:
            "Enabled automated incident creation, response or reporting using teamSOS APIs",
          onClick: () => {
            this.$router.push("/admin/integration/api-clients");
          },
          img: "/admin/api-client.jpg",
        },
      ],
    };
  },

  methods: {
    handleItemClicked(item) {
      if (item.onClick) {
        item.onClick();
      }
    },
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
    }),

    colSpan() {
      if (this.windowWidth > 1200) {
        return 8;
      }
      if (this.windowWidth > 800) {
        return 12;
      }
      if (this.windowWidth > 500) {
        return 24;
      }
      return 24;
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  margin-top: 20px;
}

.admin-menu-item {
  margin-bottom: 15px;
}
</style>