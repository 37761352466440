<template>
  <div class="admin-menu-item">
    <div class="top" v-bind:style="bannerImageStyle"></div>
    <div class="bottom">
      <div class="name">
        {{ title }}
        <tooltip :tooltip="tooltip" v-if="tooltip"></tooltip>
      </div>
      <div class="description" v-if="description">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '../Tooltip.vue';
export default {
  props: ["img", "title", "description", "tooltip"],
  components: {Tooltip},
  computed: {
    bannerImageStyle() {
      if (this.img) {
        return {
          backgroundImage: "url(" + this.img + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
        };
      }
      return {
        backgroundColor: "rgba(81, 85, 234, 0.1)",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.admin-menu-item {
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;
  overflow: hidden;

  .top {
    height: 130px;
    min-height: 130px;
    max-height: 130px;
  }

  .bottom {
    display: flex;
    padding: 20px;
    .name {
      font-weight: 500;
    }

    .description {
      margin-top: 5px;
    }
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}
</style>